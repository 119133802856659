import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './page/navbar/navbar.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { MainLayoutComponent } from './page/main-layout/main-layout.component';

import { NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbProgressbarModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './page/login/login.component';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FichasAdminComponent } from './fichas/fichas-admin/fichas-admin.component';
import { FichasSearchComponent } from './fichas/fichas-search/fichas-search.component';
import { FichasReporteComponent } from './fichas/fichas-reporte/fichas-reporte.component';
import { EditUserModalComponent } from './page/edit-user-modal/edit-user-modal.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { registerLocaleData } from '@angular/common';
import localeCO from '@angular/common/locales/es-CO';

registerLocaleData(localeCO, 'es-CO');

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowUp, faBook, faCalendar, faClipboard, faEraser, faEye, faFileExcel, faFilePdf, faRedo, faSearch, faSignOutAlt, faSort, faSortDown, faSortUp, faUser, faUserEdit, faUserPlus, faUsers, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { BtnScrollComponent } from './page/btn-scroll/btn-scroll.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { FichaEditarTrabajadorComponent } from './fichas/ficha-editar-trabajador/ficha-editar-trabajador.component';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    NotFoundComponent,
    MainLayoutComponent,
    LoginComponent,
    FichasAdminComponent,
    FichasSearchComponent,
    FichasReporteComponent,
    EditUserModalComponent,
    BtnScrollComponent,
    FichaEditarTrabajadorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModalModule,
    NgbProgressbarModule,
    NgbCollapseModule,
    NgbTooltipModule,
    Ng2GoogleChartsModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    FontAwesomeModule,
    InfiniteScrollModule,
    NgSelectModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true
    }),
    InfiniteScrollModule

  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-CO' }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faUserTimes, faUsers, faBook, faClipboard, faUser, faUserEdit, faSignOutAlt, faCalendar, faArrowUp, faSort, faSortUp, faSortDown, faUserEdit, faUserPlus, faFilePdf, faFileExcel, faRedo, faSearch, faEraser, faEye);
  }
}
