import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Contratista } from '../../class/contratista';
import { Evaluacion } from '../../class/evaluacion';
import { FichaFilter } from '../../class/filter/ficha-filter';

@Component({
  selector: 'app-fichas-search',
  templateUrl: './fichas-search.component.html',
  styleUrls: ['./fichas-search.component.scss']
})
export class FichasSearchComponent {

  @Input() fichaFilter : FichaFilter;
  @Input() evaluaciones : Evaluacion[];
  @Input() contratistas : Contratista[];
  @Output() search = new EventEmitter();
  @Output() limpiar = new EventEmitter();

  submit(){
    this.search.emit();
  }
}
