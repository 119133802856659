import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FichasAdminComponent } from './fichas/fichas-admin/fichas-admin.component';
import { MainLayoutComponent } from './page/main-layout/main-layout.component';
import { NotFoundComponent } from './page/not-found/not-found.component';



const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: "", redirectTo:'/reportes', pathMatch:"full"},
      { path: "reportes", component: FichasAdminComponent },
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
