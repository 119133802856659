<div class="modal-container">
  <div class="modal-header">
    <h3 class="modal-title">Ver Reporte {{ficha.fic_id}}</h3>
    <button type="button" class="close" aria-label="Close" (click)="cerrar.emit()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 mb-2">
        <h5>Datos Trabajador</h5>
        <table class="table table-bordered">
          <tr>
            <th>Nombre</th>
            <td>{{ficha.trabajador?.nombre}} {{ficha.trabajador?.paterno}} {{ficha.trabajador?.materno}}</td>
          </tr>
          <tr>
            <th>Cédula de Ciudadania</th>
            <td>{{ficha.trabajador?.rut}}</td>
          </tr>
          <tr>
            <th>Correo</th>
            <td>{{ficha.trabajador?.mail}}</td>
          </tr>
          <!-- <tr>
              <th>NIT Empresa</th>
              <td>{{ficha.contratista?.rut}}</td>
          </tr>
          <tr>
              <th>Empresa</th>
              <td>{{ficha.contratista?.nombre_corto}}</td>
          </tr>  -->
        </table>
      </div>
      <div class="col-12 mb-2">
        <h5>Datos Ficha</h5>
        <table class="table table-bordered">
          <thead>
          <th>Sede</th>
          <th>ID Curso</th>
          <th>Curso</th>
          <th>Fecha</th>
          <th>Calificación</th>
          </thead>
          <tbody>
          <tr>
            <td>{{ficha.params?.data?.sede}}</td>
            <td>{{ficha.params?.data?.curso}}</td>
            <td>{{ficha.evaluacion?.nombre}}</td>
            <td>{{ficha.creado | date:"dd-MM-yyyy HH:mm:ss"}}</td>
            <td>{{ficha.calificacion |percent}}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
    <div class="row" *ngIf="data.length>0 loaded" (window:resize)="onResize($event)">
      <div class="col-12">
        <google-chart [data]="pieChart"></google-chart>
      </div>
    </div>
    <div class="col-12 mb-3 mt-3">
      <h5>Consejo: </h5>
      <h6>{{mensaje}}</h6>
    </div>
  </div>
</div>
