import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Trabajador } from '../class/trabajador';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Ficha } from '../class/ficha';
import { FichaParams } from '../class/ficha-params';
import { Observable } from 'rxjs';
import { Evaluacion } from '../class/evaluacion';
import { Contratista } from '../class/contratista';

export interface MelState {
  fichas: Ficha[];
  fichasparams: FichaParams[];
  contratistas: Contratista[];
  // evaluaciones: Evaluacion[];
  lastUpdate: MelLastUpdate;
}

export interface MelLastUpdate {
  lastChange: string;
}


@Injectable({
  providedIn: 'root'
})
export class MelService {

  private base = `${environment.endpoint}/v1/transversal`;
  constructor(private http: HttpClient, private auth: AuthService) { }

  GetFichas(params) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(`${this.base}`, { headers, params, observe:'response' });
  }
  GetFichasparams(params) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<FichaParams[]>(`${this.base}`, { headers, params, observe:'response' });
  }

  GetEvaluaciones(){
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Evaluacion[]>(`${this.base}/evaluaciones`, { headers, observe:'response' });
  }

  GetContratistas(){
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Contratista[]>(`${this.base}/contratistas`, { headers, observe:'response' });
  }

  GetReporteMasivo(){
    let params= new HttpParams();
    params = params.set(`modulo`, "MEL");
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get(`${this.base}/reportemasivo`, { headers, params, responseType:'arraybuffer' });
  }
}
