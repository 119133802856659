<!-- Headers -->
<div class="row">
  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
    <h1>Administración <small class="text-muted">Reportes</small></h1>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2 d-flex justify-content-end">
    <div>
      <button class="btn btn-secondary mr-2 mb-1" (click)="init()">
        <fa-icon [icon]="['fas','redo']"></fa-icon> Actualizar
      </button>
      <button class="btn btn-success mr-2 mb-1" (click)="downloadExcel()">
        <fa-icon [icon]="['fas','file-excel']"></fa-icon> Reporte
      </button>
      <button class="btn btn-primary mb-1" (click)="isSearchCollapsed = !isSearchCollapsed">
        <fa-icon [icon]="['fas','search']"></fa-icon> Búsqueda Avanzada
      </button>
    </div>
  </div>
</div>

<!-- Búsqueda Avanzada -->
<div class="row collapse" [ngbCollapse]="isSearchCollapsed" id="advanceSearch">
  <div class="col-12">
    <app-fichas-search [fichaFilter]="fichaFilter" [contratistas]="contratistas" [evaluaciones]="evaluaciones"
                       (search)="busquedaAvanzada()" (limpiar)="limpiar()"></app-fichas-search>
  </div>
</div>

<!-- Tabla de datos 
<div class="row" infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScroll()">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-bordered table-hover">
        <thead>
        <th>N°</th>
        <th scope="col" *ngFor="let item of sort.menu"
            [ngClass]="{ 'text-primary': sort.name == item.sort }" [ngStyle]="{ cursor: 'pointer'}"
            (click)="setOrder(item.sort)">
          <fa-icon [icon]="iconOrder(item.sort)"></fa-icon> {{item.title}}
        </th>
        <th>Opciones</th>
        </thead>
        <tbody>
        <tr *ngFor="let ficha of fichasparams; let i = index">
          <!--<td>{{i+1}}</td>
          <td>{{ficha.fic_id}}</td>
          <td>{{ficha.creado}}</td>
          <td>{{ficha.content | json}}</td>
           <td>{{ficha.evaluacion?.nombre}}</td>
          <td style="white-space: nowrap;">{{ficha.trabajador?.rut}}</td>
          <td>{{ficha.trabajador?.nombre}}</td>
          <td>{{ficha.trabajador?.paterno}}</td>
          <td>{{ficha.trabajador?.materno}}</td>
          <td style="text-align: end;">{{ficha.calificacion | percent}}</td>
          <td style="white-space: nowrap;">{{ficha.creado | date:'dd-MM-yyyy HH:mm:ss'}}</td>
          <td>
            <div class="d-flex justify-content-around">
              <button ngbTooltip="ver reporte" (click)="open(reporte, ficha)"
                      class="btn btn-sm btn-info ml-1 mr-1">
                <fa-icon [icon]="['fas','eye']"></fa-icon>
              </button>
              <button ngbTooltip="editar trabajador" (click)="open(editar, ficha)"
                      class="btn btn-sm btn-primary ml-1 mr-1">
                <fa-icon [icon]="['fas','user-edit']"></fa-icon>
              </button>
              <button ngbTooltip="descargar PDF" class="btn btn-sm btn-danger ml-1 mr-1"
                      (click)="getPdf(ficha.fic_id)">
                <fa-icon [icon]="['fas','file-pdf']"></fa-icon>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>-->

<!-- Modal Ver Reporte -->
<ng-template #reporte>
  <app-fichas-reporte [fichaTemp]="fichaTemp" (cerrar)="close()"></app-fichas-reporte>
</ng-template>

<!-- Modal Editar Trabajador -->
<ng-template #editar>
  <app-ficha-editar-trabajador [fichaTemp]="fichaTemp" [contratistas]="contratistas" (cerrar)="close()"
                               (guardar)="guardar($event)"></app-ficha-editar-trabajador>
</ng-template>
